import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "../styles/homePage.scss";
import {useLocation} from "react-router-dom";


export function NotFound404() {

    const loc = useLocation()


    return <Container fluid className="home-page">
        <Helmet>
            <title>Not Found (404) | thinkHub</title>
        </Helmet>
        <Row className="home-page-banner-bg-image">
            <Col className="justify-content-xs-center home-page-banner image-overlay w-100 h-100">
                <Container data-aos="zoom-in">
                    <Col xs={12} className="text-center home-page-banner-heading">
                        Not Found | 404
                    </Col>
                    <Col xs={12} className="text-center Xhome-page-banner-heading">
                        {loc.pathname}
                    </Col>
                </Container>
            </Col>
        </Row>
    </Container>
}

export default NotFound404;