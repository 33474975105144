import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";


export function ThinkHubHeader(props) {

  const [colorChange, setColorChange] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
        setColorChange(true);
      }
      else {
        setColorChange(false);
      }
    };
    window.addEventListener('scroll', changeNavbarColor);

    const navClickListener = (event) => {
      let navMenuElement = document.getElementById("responsive-thinHub-navbar-nav");
      const isClickInside = navMenuElement.contains(event.target);

      if (!isClickInside) {
        setNavbarExpanded(false);
      }
    }
    document.addEventListener('click', navClickListener);

    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
      document.removeEventListener('click', navClickListener);
    }
  }, [])
  

  return <>
    <Navbar expanded={navbarExpanded} expand="lg" sticky="top" className={colorChange ? "scrolled think-hub-nav-bar" : "think-hub-nav-bar"}>
      <Container>
        <Navbar.Brand as={NavLink} to={"/"}>
          <span className="navbar-logo">thinkHub</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-thinHub-navbar-nav" onClick={(e) => {
          e.stopPropagation();
          setNavbarExpanded((prevNavbarExpanded) => !prevNavbarExpanded)
        }} />
        <Navbar.Collapse id="responsive-thinHub-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} onClick={() => setNavbarExpanded((prevNavbarExpanded) => !prevNavbarExpanded)} to={"/home"}>
              <Button variant="link">Home</Button>
            </Nav.Link>
            <Nav.Link as={NavLink} onClick={() => setNavbarExpanded((prevNavbarExpanded) => !prevNavbarExpanded)} to={"/about"}>
              <Button variant="link">About Us</Button>
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to={"/factsheet/abc"} state={{iframeLink: true}}>
              <Button variant="link">Axxxx</Button>
            </Nav.Link> */}
            <Nav.Link as={NavLink} onClick={() => setNavbarExpanded((prevNavbarExpanded) => !prevNavbarExpanded)} to={"/invest"}>
              <Button variant="custom-success">Invest Now</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
}