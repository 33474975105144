
import { Helmet } from "react-helmet";
import {Routes, Route, Outlet, useNavigationType} from "react-router-dom";
import { Container } from "react-bootstrap";
import { ThinkHubHeader } from "./components/thinkHubHeader";
import {Suspense, lazy, useEffect, useLayoutEffect} from "react";
import "./styles/thinkHub.scss";
import { ThinkHubFooter } from "./components/thinkHubFooter";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import NotFound404 from "./pages/notFound404";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "animate.css";
// import "owl.carousel/dist/assets/owl.carousel.css"
// import "owl.carousel";


const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Invest = lazy(() => import("./pages/invest"));
const FactSheet = lazy(() => import("./pages/factsheet"))
const BlogPage = lazy(() => import("./pages/blogPage"))

AOS.init();

if(process.env.REACT_APP_GA4_ENABLED=='true' && process.env.REACT_APP_GA4_MEASUREMENT_ID) {
  //console.log("INIT GA4 enabled:"+process.env.REACT_APP_GA4_ENABLED+" mid:"+process.env.REACT_APP_GA4_MEASUREMENT_ID)
  ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID)
}else{
  console.log("GA4 DISABLED")
}

function App() {

  const loc = useLocation()
  const navType = useNavigationType()
  let prevPage = null

  useEffect(() => {
    let navPage = loc.pathname + loc.search + loc.hash
    if (navPage != prevPage) { //this approach seems to trigger the action twice sometimes, avoid sending duplicate GA pageviews
      prevPage = navPage
      //console.log("Nav: "+navType+"   Page: "+navPage, loc)
      if(process.env.REACT_APP_GA4_ENABLED=='true' && process.env.REACT_APP_GA4_MEASUREMENT_ID) {
       ReactGA.send({hitType: "pageview", page: navPage  /*,title: "Custom Title"*/});
      }
    }

  }, [loc])


  return <>
    <Routes>
      <Route path="/" element={<NavWrapper />} >
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/invest" element={<Invest/>} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/factsheet/:easyAccessToken" element={<FactSheet/>} />
        <Route path="*" element={<NotFound404 />} />
      </Route>

    </Routes>
  </>
}

function NavWrapper() {

  return <>
    <Container fluid className="p-0 min-vh-100 think-hub-style" think-hub-theme="light">
      <ThinkHubHeader/>
      <Suspense>
        <Outlet />
      </Suspense>
      <ThinkHubFooter/>
    </Container>
  </>

}

export default App;
